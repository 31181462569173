import _Draggable2 from "./Draggable";
import _Droppable2 from "./Droppable";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Draggable", {
  enumerable: true,
  get: function get() {
    return _Draggable.default;
  }
});
Object.defineProperty(exports, "Droppable", {
  enumerable: true,
  get: function get() {
    return _Droppable.default;
  }
});

var _Draggable = _interopRequireDefault(_Draggable2);

var _Droppable = _interopRequireDefault(_Droppable2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

export default exports;
export const __esModule = exports.__esModule,
      Draggable = exports.Draggable,
      Droppable = exports.Droppable;